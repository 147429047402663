<!--  -->
<template>
  <div class='news-view' v-if="list.length > 0">
    <div class="news-hint">
      <div>
        <img src="../../../assets/img/needle.png" class="needle" alt="">
        <el-carousel trigger="click" height="250px" class="carousel" indicator-position="none" @change="onChange">
          <el-carousel-item v-for="(item, index) in bannerArr" :key="index" class="item">
            <div class="item" @click="handleBanner(item.src)">
              <div class="title">2024年广东省执业药师网络<br>远程继续教育</div>
              <div class="img" :style="{'background-image': 'url(' + item.image + ')'}"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <img src="../../../assets/img/next.png" class="next" alt="" @click="handleBanner(bannerArr[idx].src)">
      </div>
    </div>
    <div class="news-arr">
      <div v-for="(item, index) in list" :key="index" @click="handleItem(item.id)">
        <div>
          <div>{{item.title}}</div>
          <span>{{item.years}}</span>
        </div>
        <div>
          <div>{{item.describe}}</div>
          <img src="../../../assets/img/next.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../../api/my"

export default {
  components: {},
  data() {
    return {
      list: [],
      bannerArr: [],
      idx: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getConsult()
    this.getConsultBanner()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getConsultBanner () {
      Api.getConsultBanner({}).then(res => {
        if (res.data.code == 1) {
          this.bannerArr = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleBanner (src) {
      window.location = src
    },
    onChange (idx) {
      this.idx = idx
    },
    getConsult () {
      Api.getConsult({
        page: 1,
        limit: 3,
        status: '新闻'
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
          this.list.forEach(item => {
            item.describe = item.describe ? item.describe.slice(0, 80) + '...' : item.describe
            item.years = item.add_time.split(" ")[0]
          })
        } else {
          this.$message.error("新闻数据获取失败")
        }
        if (this.list.length == 0) {
          this.$emit('hide')
        }
      })
    },
    handleItem (id) {
      this.$router.push({name: 'ConsultDetail', query: {id: id}})
    }
  },
}
</script>

<style lang='less' scoped>
.news-view {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  .news-hint {
    width: 320px;
    height: 340px;
    position: relative;
    margin-top: 50px;
    background-color: #09B38A;
    > div {
      background-color: #F6F6F6;
      width: 360px;
      position: absolute;
      bottom: 15px;
      left: 20px;
      padding: 60px 20px 15px;
      .needle {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
      }
      .carousel {
        width: 100%;
        margin-bottom: 10px;
        .item {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          .title {
            font-size: 22px;
            font-weight: bold;
            color: #494949;
          }
          .img {
            width: 100%;
            flex: 1;
            background-size: 100% 100%;
          }
        }
      }
      .next {
        cursor: pointer;
      }
    }
  }

  .news-arr {
    flex: 1;
    margin-left: 200px;
    border-top: 1px solid #707070;
    display: flex;
    flex-direction: column;
    > div {
      flex: 1;
      padding: 10px 0;
      border-bottom: 1px solid #707070;
      cursor: pointer;
      > div {
        display: flex;
        justify-content: space-between;
        > div {
          flex: 1;
          margin-right: 100px;
          white-space: pre-wrap;
          word-break: break-all;
          font-size: 20px;
          font-weight: bold;
        }
        span {
          font-size: 16px;
          color: #909090;
          font-weight: normal;
        }
      }
      > div:last-child {
        margin-top: 10px;
        align-items: center;
        div {
          font-size: 16px;
          color: #909090;
          font-weight: normal;
        }
        img {
          width: 30px;
        }
      }
    }
  }
}
</style>